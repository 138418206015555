import {
  composeRenderProps,
  Link,
  type LinkProps as RACLinkProps,
  Button as RACButton,
  type ButtonProps as RACButtonProps,
} from "react-aria-components";
import { tv } from "tailwind-variants";
import { focusRing } from "./utils";
import { AnimatePresence, motion } from "framer-motion";
import Loader from "./loader";
import { forwardRef } from "react";
import classNames from "~/utils/class-names";

export interface ButtonProps extends RACButtonProps {
  variant?: "primary" | "secondary" | "destructive" | "icon" | "text";
  showLoader?: boolean;
}

export interface LinkProps extends RACLinkProps {
  variant?: "primary" | "secondary" | "destructive" | "icon" | "text";
  showLoader?: boolean;
  to?: string;
}

export const ButtonStyles = tv({
  extend: focusRing,
  base: "relative px-3.5 py-1.5 text-sm text-center transition-[background-color] rounded-lg border border-black/10 dark:border-white/10 shadow-[inset_0_1px_0_0_rgba(255,255,255,0.1)] dark:shadow-none cursor-pointer",
  variants: {
    variant: {
      primary: "bg-zinc-800 hover:bg-zinc-900 pressed:bg-zinc-950 text-white",
      secondary: "bg-white hover:bg-zinc-50 pressed:bg-zinc-100 text-zinc-950",
      destructive: "bg-red-500 hover:bg-red-600 pressed:bg-red-700 text-white",
      icon: "border-0 p-1 flex items-center justify-center text-zinc-950 hover:bg-black/[5%] pressed:bg-black/10 dark:text-zinc-400 dark:hover:bg-white/10 dark:pressed:bg-white/20 disabled:bg-transparent shadow-none",
      text: "bg-transparent p-0 border-none rounded-sm",
    },
    isDisabled: {
      true: "bg-gray-100 dark:bg-zinc-800 text-gray-300 dark:text-zinc-600 forced-colors:text-[GrayText] border-black/5 dark:border-white/5",
    },
  },
  defaultVariants: {
    variant: "primary",
  },
});

export const Button = forwardRef<
  HTMLButtonElement,
  ButtonProps & Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "ref">
>(function ButtonForwarded({ children, showLoader, ...props }, ref) {
  return (
    <RACButton
      ref={ref}
      {...props}
      onPress={(e) => {
        if (props.onPress && !showLoader) props.onPress(e);
      }}
      className={composeRenderProps(props.className, (className, renderProps) =>
        ButtonStyles({
          ...renderProps,
          variant: props.variant,
          className: classNames(className, showLoader ? "*:opacity-0 text-transparent" : ""),
        })
      )}
    >
      <>
        {children}
        {(showLoader || props.isPending) && (
          <AnimatePresence>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="absolute inset-0 flex items-center justify-center text-white"
            >
              <Loader />
            </motion.div>
          </AnimatePresence>
        )}
      </>
    </RACButton>
  );
});

export const LinkButton = forwardRef<
  HTMLAnchorElement,
  LinkProps & Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, "ref">
>(function LinkForwarded({ children, showLoader, ...props }, ref) {
  if (props.to) {
    props.href = props.to;
  }
  return (
    <Link
      ref={ref}
      {...props}
      className={composeRenderProps(props.className, (className, renderProps) =>
        ButtonStyles({
          ...renderProps,
          variant: props.variant,
          className: classNames(className, showLoader ? "*:opacity-0 text-transparent" : ""),
        })
      )}
    >
      <>
        {children}
        {showLoader && (
          <AnimatePresence>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="absolute inset-0 flex items-center justify-center text-white"
            >
              <Loader />
            </motion.div>
          </AnimatePresence>
        )}
      </>
    </Link>
  );
});
